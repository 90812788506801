import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

const fasIcons = [
  fas.faBagShopping,
  fas.faBasketShopping,
  fas.faChevronLeft,
  fas.faChevronRight,
  fas.faCircleCheck,
  fas.faCircleInfo,
  fas.faCircle,
  fas.faFileImport,
  fas.faLock,
  fas.faMagnifyingGlass,
  fas.faMinus,
  fas.faMinusCircle,
  fas.faPenToSquare,
  fas.faPlus,
  fas.faTrash,
  fas.faTrashCan,
  fas.faUser,
  fas.faUserPen,
  fas.faEye,
  fas.faFileAlt,
  fas.faKey,
  fas.faFileImport,
  fas.faEyeSlash,
  fas.faXmark,
  fas.faCircleXmark,
  fas.faCircleExclamation,
  fas.faBars,
  fas.faPlus,
  fas.faUserPlus,
  fas.faTimesCircle,
  fas.faTimes,
  fas.faCaretUp,
  fas.faCaretDown,
  fas.faAngleDown,
  fas.faCaretRight,
  fas.faAngleRight,
  fas.faEllipsis,
  fas.faSquarePlus,
  fas.faSquareMinus,
  fas.faFilter,
  fas.faFilePdf,
  fas.faDownload,
  fas.faFileExport,
  fas.faList,
  fas.faGripVertical,
  fas.faShareAlt,
  fas.faArrowLeft,
  fas.faTags,
  fas.faBox,
  fas.faFileZipper,
  fas.faArrowUp,
  fas.faArrowDown,
  fas.faGavel,
  fas.faBoxOpen,
  fas.faFileInvoiceDollar,
  fas.faTruckLoading,
  fas.faLink,
  fas.faCreditCard,
  fas.faShieldAlt,
  fas.faBan,
  fas.faWallet,
  fas.faHandHoldingUsd,
  fas.faPiggyBank,
  fas.faFloppyDisk,
  fas.faPercent,
  fas.faCircleUser,
  fas.faLocationPin,
  fas.faArrowTrendUp,
  fas.faPlay,
  fas.faHandshake,
  fas.faAddressCard,
  fas.faComment,
  fas.faMedal,
  fas.faSyncAlt,
  fas.faClone
]

const farIcons = [
  far.faUser,
  far.faCircleUser,
  far.faFile,
  far.faFloppyDisk,
  far.faEye,
  far.faTrashCan,
  far.faHeart,
  far.faFilePdf,
  far.faEnvelope
]

const fabIcons = [
  fab.faFacebookSquare,
  fab.faTwitter,
  fab.faSquareXTwitter,
  fab.faInstagram,
  fab.faWhatsapp
]

export default function registerComponents() {
  fasIcons.forEach((icon) => {
    library.add(icon)
  })
  fabIcons.forEach((icon) => {
    library.add(icon)
  })
  farIcons.forEach((icon) => {
    library.add(icon)
  })
}
