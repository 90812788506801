<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { instance, factories } from '@/api'
import { useShoppingStore } from '@/stores/shopping'
import CardProductDetail from '@/components/shared/CardProductDetail.vue'
import TableHelper from '@/components/shared/TableHelper.vue'
import { useUserStore } from '@/stores/user'

const api = factories(instance, ['product'])
// const images = import.meta.globEager('../assets/images/marcas/*.svg')

const userStore = useUserStore()
const isSeller = computed(() => userStore.isSeller)
const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})
const canViewProductsByClientNum = computed(() => userStore.canViewProductsByClientNum)
const route = useRoute()
const publicRoutesRequiredValidations = [
  'product_detail_public',
  'product_detail_only_list_pack_public',
  'category_public'
]
const categoryPublic = [714, 715, 6]
const searchValue = ref('')
const product = ref(null)
const productOfPack = ref(null)
const selectedTypeView = ref('grip-vertical')
const tableFields = [
  {
    key: 'price',
    label: 'Total',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'quantity',
    label: 'Cantidad',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'code',
    label: 'Código',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'key',
    label: 'Clave',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  },
  {
    key: 'title',
    label: 'Título',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle',
    sortable: true
  },
  {
    key: 'description',
    label: 'Descripción',
    thClass: 'text-center fw-semibold text-app',
    tdClass: 'text-center align-middle'
  }
]
const amount = ref(1)
const overStockIssue = ref(null)
const shoppingStore = useShoppingStore()
const isExcludedUserByClientNum = computed(() => userStore.isExcludedUserByClientNum)
const isClientCredit = computed(() => userStore.isClientCredit)

const isRoutePublic = computed(() => publicRoutesRequiredValidations.includes(route.name))
const publicCategoryOfProduct = computed(() => {
  if (!product.value) return false
  return (
    product.value.categories.filter((category) => categoryPublic.includes(category))[0] || false
  )
})

const isThisProductInCart = computed(() => shoppingStore.isThisProductInCart(product.value.id))

const amountStore = computed({
  get: () => shoppingStore.getAmountByID(product.value.id),
  set: (newValue) => {
    shoppingStore.changeAmountWithoutNotification(product.value.id, newValue)
  }
})

const subtractOne = (id) => {
  shoppingStore.subtractOne(id)
}

const addOne = (id) => {
  shoppingStore.addOne(id)
}

const changeAmount = (id, amount) => {
  shoppingStore.changeAmount(id, amount)
}
// const capitalizedBrandName = computed(() => {
//   if (!product.value.brand) return ''
//   return product.value.brand.charAt(0).toUpperCase() + product.value.brand.slice(1)
// })

const hasProductStock = computed(() => !!product.value.stock)

const stock = computed(() => product.value.stock)

const productOfPackFilter = computed(() => {
  if (!productOfPack.value) return []
  return productOfPack.value.filter((product) => {
    const search = searchValue.value.toLowerCase()
    return (
      product?.code?.toString().toLowerCase().includes(search) ||
      product?.key?.toString().toLowerCase().includes(search) ||
      product?.title?.toString().toLowerCase().includes(search) ||
      product?.description?.toString().toLowerCase().includes(search)
    )
  })
})

function changeView() {
  selectedTypeView.value = selectedTypeView.value === 'grip-vertical' ? 'list' : 'grip-vertical'
}

function validateAmount() {
  const amountData = JSON.parse(JSON.stringify(amount.value))
  if (amount.value > stock.value) {
    overStockIssue.value = `La cantidad ${amountData} supera el stock disponible`
    amount.value = stock.value
    return
  }
  if (amount.value < 1) {
    amount.value = 1
  }
  overStockIssue.value = null
}

// function getBrandImage(brandName) {
//   const image = images[`../assets/images/marcas/${brandName}.svg`]
//   return image ? image.default : null
// }

function getProduct() {
  const payload = {}
  if (clientSelected.value && canViewProductsByClientNum.value)
    payload.client_id = clientSelected.value
  api.product.detail(route.params.id, payload).then((res) => {
    const someCategoryIsPublic = res.data.categories.some((category) =>
      categoryPublic.includes(category)
    )
    if (someCategoryIsPublic && isRoutePublic.value) {
      getProductPublic()
      return
    }
    product.value = res.data
  })
}

function getProductPublic() {
  api.product.detailPublic(route.params.id).then((res) => {
    product.value = res.data
    productOfPack.value = res.data.products
  })
}

function addProduct() {
  shoppingStore.addItem(product.value, amount.value)
  amount.value = 1
}

function shareOnWhatsApp() {
  const message = 'Hola, echa un vistazo a este increíble producto: ' + window.location.href
  const encodedMessage = encodeURIComponent(message)
  const whatsappUrl = 'https://wa.me/?text=' + encodedMessage
  window.open(whatsappUrl, '_blank')
}

function shareEmail() {
  const message = 'Hola, echa un vistazo a este increíble producto: ' + window.location.href
  const encodedMessage = encodeURIComponent(message)
  const emailUrl = 'mailto:?subject=¡Mira este producto!&body=' + encodedMessage
  window.open(emailUrl, '_blank')
}

onMounted(() => {
  if (!isRoutePublic.value) {
    getProduct()
  } else {
    getProductPublic()
  }
})

defineExpose({
  publicCategoryOfProduct
})
</script>

<template>
  <div
    :class="{
      'my-5': !isThisProductInCart,
      'border border-primary-app rounded py-5': isThisProductInCart
    }"
    v-if="product"
  >
    <div class="row justify-content-between">
      <div class="col-12 col-md-6 mb-3 position-relative">
        <img class="img-fluid rounded" :src="product.image" alt="" />
        <div class="position-absolute float_element-right cursor-pointer" v-if="!isRoutePublic">
          <font-awesome-icon class="me-2 mt-0 text-app" :icon="['far', 'heart']" size="xl" />
        </div>
        <!-- <div
          class="position-absolute top-0 start-0 z-1"
          v-if="product.brand && getBrandImage(capitalizedBrandName)"
        >
          <img
            :src="getBrandImage(capitalizedBrandName)"
            alt="Brand Image"
            class="img-fluid brand-image-detail"
          />
        </div> -->
        <div class="position-absolute float_element_bottom-right" v-if="product.sold_out">
          <div class="badge rounded-pill text-bg-danger">
            <h5 class="m-0 m-1 mx-2">AGOTADO</h5>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h4 class="mb-1" v-text="product.title"></h4>
        <h4 class="mb-1" v-if="!isRoutePublic && product.multiple">
          <font-awesome-icon icon="fa-solid fa-box" class="me-2 text-400" />
          Múltiplo: <span class="text-muted">{{ product.multiple }}</span>
        </h4>
        <p class="fw-normal" v-text="`Código: ${product.code}`"></p>
        <p class="fw-semibold mb-1" v-text="'Información del producto'"></p>
        <p class="fw-normal mb-0" v-text="`Clave de producto: ${product.key}`"></p>
        <p class="fw-normal" v-text="product.description"></p>
        <img
          v-if="product?.categories?.includes(5)"
          src="@/assets/images/av_icon.svg"
          alt="Brand Image"
          class="img-fluid av-image-width-card"
        />
        <p class="fw-semibold mb-1" v-if="!isRoutePublic && product.free_tax">
          <font-awesome-icon icon="fa-solid fa-info-circle" class="me-2" />
          Este producto está exento de IVA.
        </p>
        <p class="fw-semibold text-app" v-if="product.competition_level && !isRoutePublic">
          NC: {{ product.competition_level }}
        </p>
        <p class="fw-semibold text-app" v-if="product.stock && isClientCredit">
          Existencia: {{ product.stock }}
        </p>

        <a
          v-if="!isRoutePublic"
          class="fw-semibold text-decoration-none text-black cursor-pointer"
          :href="product.data_sheet"
          target="_blank"
          >Ficha Técnica</a
        >
        <h1 class="text-black mb-3">{{ $formatcurrency(product.price_discount) }}</h1>
        <div v-if="product.sold_out">
          <div class="badge rounded-pill text-bg-danger mb-2">
            <h5 class="m-0 m-1 mx-3">AGOTADO</h5>
          </div>
        </div>
        <div v-if="product.discount > 0 && !product.sold_out">
          <div class="badge rounded-pill text-bg-danger mb-2">
            <h5 class="m-0 m-1 mx-3">{{ product.discount }}%</h5>
          </div>
          <p class="fw-normal">
            <span class="text-decoration-line-through me-3 red-strike">{{
              $formatcurrency(product.price)
            }}</span
            >Ahorra {{ product.discount }}%
          </p>
        </div>
        <template v-if="product.stock && isClientCredit">
          <div
            class="d-flex align-items-center"
            :class="{ 'mb-3': !overStockIssue, 'mb-0': overStockIssue }"
            v-if="!isRoutePublic"
          >
            <label class="me-3">Cantidad:</label>
            <input
              v-model="amount"
              @keyup.enter="addProduct"
              v-numeric
              @input="validateAmount"
              type="number"
              class="form-control w-20 bg-transparent"
              :max="hasProductStock ? stock : 99999"
            />
          </div>
          <small class="text-danger d-block mb-3" v-if="overStockIssue">{{ overStockIssue }}</small>
        </template>
        <template v-else-if="!isSeller && !isThisProductInCart">
          <div class="d-flex align-items-center mb-3" v-if="!isRoutePublic">
            <label class="me-3">Cantidad:</label>
            <input
              v-model="amount"
              @keyup.enter="addProduct"
              v-numeric
              type="number"
              class="form-control w-20 bg-transparent"
              :max="99999"
            />
          </div>
        </template>
        <template v-else-if="isThisProductInCart">
          <label class="me-3">Cantidad:</label>
          <div class="d-flex align-items-center mb-3" v-if="!isRoutePublic">
            <div class="input-group mb-3">
              <button
                class="btn btn-outline-secondary border z-1"
                type="button"
                @click="subtractOne(product.id)"
              >
                <font-awesome-icon icon="fa-solid fa-minus" />
              </button>
              <input
                type="number"
                class="form-control text-center"
                v-min-value
                v-numeric
                @keyup.enter="changeAmount(product.id, amountStore)"
                v-model="amountStore"
              />
              <button
                class="btn btn-outline-secondary border z-1"
                type="button"
                @click="addOne(product.id)"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </button>
            </div>
          </div>
        </template>
        <button
          v-if="!isSeller && !isRoutePublic && !isExcludedUserByClientNum && !isThisProductInCart"
          @click="addProduct"
          class="btn btn-primary-app px-5"
          :disabled="product.sold_out"
        >
          {{ !product.sold_out ? 'Añadir al carrito' : 'Agotado' }}
        </button>
        <div class="row my-3">
          <div class="col-12 col-md-6">
            <p class="fw-semibold mb-1">Compartir</p>
            <div class="d-flex">
              <a class="me-3" @click="shareOnWhatsApp">
                <font-awesome-icon class="text-app" :icon="['fab', 'whatsapp']" size="2x" />
              </a>
              <a @click="shareEmail">
                <font-awesome-icon class="text-app" :icon="['far', 'envelope']" size="2x" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="productOfPack && product.products.length > 0">
      <div class="row mb-3">
        <div class="col d-flex align-content-end">
          <h4 class="my-auto">Contenido:</h4>
        </div>
        <div class="col text-end">
          <p class="mb-0">
            Vista:
            <span @click="changeView">
              <button class="btn btn-primary-app">
                <font-awesome-icon class="text-white" :icon="['fa', selectedTypeView]" size="xl" />
              </button>
            </span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="input-search search-input">
            <input
              class="input-search form-control border-end-0 border"
              type="search"
              v-model="searchValue"
              @change="emptyText"
              @keyup.enter="emit('search', $event.target.value)"
              placeholder="Busqueda por código, clave, título o descripción"
              id="example-search-input"
            />
            <button class="clear-icon" type="button" @click="searchValue = ''" v-if="searchValue">
              <font-awesome-icon icon="fa-solid fa-times" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="productOfPackFilter.length === 0 && selectedTypeView != 'list'" class="mt-3">
        <p class="text-center">No se encontraron productos con el término {{ searchValue }}</p>
      </div>
      <div class="row mt-2" v-if="selectedTypeView === 'list'">
        <TableHelper
          :fields="tableFields"
          :records="productOfPackFilter"
          :tableClass="'table-bordered'"
        />
      </div>
      <div class="row" v-if="selectedTypeView === 'grip-vertical'">
        <div
          class="col-12 col-md-12 col-lg-6 my-2"
          v-for="product in productOfPackFilter"
          :key="product.id"
        >
          <CardProductDetail :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>
