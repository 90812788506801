<template>
  <GeneralModal
    ref="modalRef"
    @shown="onShown"
    :title="'Cambio de contraseña'"
    size="lg"
    modalContentClass="p-4"
    hideHeaderClose
    hideFooter
    :noCloseOnBackdrop="!user"
    :noCloseOnEsc="!user"
  >
    <template #bodyContent>
      <form @submit.prevent="submit()" v-if="!isHelpViewActive">
        <p class="text-black"><b>Contraseña</b></p>
        <div>
          <div class="form-group mb-3 input-wrapper">
            <input
              :type="inputPassword"
              class="form-control mb-3"
              :class="{ 'is-invalid': errors.password }"
              name="password"
              placeholder="Contraseña"
              v-model="password"
              v-clearInput
            />
            <font-awesome-icon
              v-if="inputPassword == 'password'"
              class="input-icon password text-500"
              :icon="['fas', 'eye-slash']"
              @click="inputPassword = 'text'"
            />
            <font-awesome-icon
              v-else
              class="input-icon password text-500"
              :icon="['fas', 'eye']"
              @click="inputPassword = 'password'"
            />
          </div>
          <small :state="errors">
            <ul class="pl-3 py-2">
              <li
                :class="{
                  'text-yellow-400': valuesLength == 0 || password?.length < 6,
                  'text-success': valuesLength != 0 && password?.length >= 6
                }"
              >
                Requiere mínimo 6 caracteres
              </li>
              <li
                :class="{
                  'text-yellow-400': valuesLength == 0 || !containUpperCase(true),
                  'text-success': valuesLength != 0 && containUpperCase(true)
                }"
              >
                Requiere al menos una mayúscula
              </li>
              <li
                :class="{
                  'text-yellow-400': valuesLength == 0 || !containUpperCase(false),
                  'text-success': valuesLength != 0 && containUpperCase(false)
                }"
              >
                Requiere al menos una minúscula
              </li>
              <li
                :class="{
                  'text-yellow-400': valuesLength == 0 || !containNumber(),
                  'text-success': valuesLength != 0 && containNumber()
                }"
              >
                Requiere al menos un número
              </li>
              <li
                :class="{
                  'text-yellow-400': valuesLength == 0 || !containSpecialCharacter(),
                  'text-success': valuesLength != 0 && containSpecialCharacter()
                }"
              >
                Requiere al menos un cáracter especial(#?!@$%^&*-)
              </li>
            </ul>
          </small>
        </div>
        <p class="text-black mt-3"><b>Confirmar contraseña</b></p>
        <div>
          <div class="form-group mb-3 input-wrapper">
            <input
              :type="inputConfirmationPassword"
              class="form-control"
              :class="{ 'is-invalid': errors.password_confirmation }"
              name="password_confirmation"
              placeholder="Confirmar contraseña"
              v-model="password_confirmation"
              v-clearInput
            />
            <font-awesome-icon
              v-if="inputConfirmationPassword == 'password'"
              class="input-icon password text-500"
              :class="{ 'me-4': errors.password_confirmation }"
              :icon="['fas', 'eye-slash']"
              @click="inputConfirmationPassword = 'text'"
            />
            <font-awesome-icon
              v-else
              class="input-icon password text-500"
              :class="{ 'me-4': errors.password_confirmation }"
              :icon="['fas', 'eye']"
              @click="inputConfirmationPassword = 'password'"
            />
          </div>
          <small class="text-danger" v-text="errors.password_confirmation"></small>
        </div>
        <div class="d-flex col mt-3">
          <button
            v-if="user"
            @click="cancelForm"
            type="button"
            class="me-3 btn btn-login-app w-100"
          >
            Cancelar
          </button>
          <button v-else @click="showHelp" type="button" class="me-3 btn btn-primary-app w-100">
            Ayuda
          </button>
          <button :disabled="!extraValidation()" class="btn btn-login-app w-100" type="submit">
            Guardar
          </button>
        </div>
      </form>
      <div v-else>
        <VideoContainer :videoSrc="videoSrc" title="Video de ayuda" />
        <div>
          <button class="btn btn-primary-app float-end" @click="hideHelp">Regresar</button>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>
<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import VideoContainer from '@/components/shared/VideoContainer.vue'
import { ref, computed, toRefs } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'
import { instance, factories } from '@/api'
import { useAuthStore } from '@/stores/auth'
import Swal from 'sweetalert2'

const inputPassword = ref('password')
const inputConfirmationPassword = ref('password')

const api = factories(instance, ['user'])
const authStore = useAuthStore()

const isHelpViewActive = ref(false)
const videoSrc = ref('')

const props = defineProps({
  user: { type: Object, required: false, default: null }
})

const { user } = toRefs(props)

const modalRef = ref(null)

const showHelp = () => {
  isHelpViewActive.value = true
  videoSrc.value = 'https://www.youtube.com/embed/YQHNtA6T-nI'
}

const hideHelp = () => {
  isHelpViewActive.value = false
  videoSrc.value = ''
}

const showModal = () => {
  modalRef.value.show()
}

// eslint-disable-next-line no-unused-vars
const hideModal = () => {
  modalRef.value.hide()
}

const getValidationSchema = computed(() => {
  const schema = {}
  schema.password = string()
    .required('La contraseña es requerido')
    .min(6, 'La contraseña debe tener al menos 6 caracteres')
  schema.password_confirmation = string()
    .required()
    .test('passwords-match', 'Las contraseñas deben ser iguales', function (value) {
      return this.parent.password === value
    })

  return object(schema)
})

const valuesLength = computed(() => {
  return Object.values(JSON.parse(JSON.stringify(values))).length
})

const validationSchema = getValidationSchema

const { handleSubmit, errors, meta, resetForm, values } = useForm({
  validationSchema
})

const { value: password } = useField('password')
const { value: password_confirmation } = useField('password_confirmation')

const submit = handleSubmit(() => {
  const payload = generatePayload()
  if (user.value) {
    api.user.change_password(user.value.id, payload).then(() => {
      cancelForm()
    })
  } else {
    api.user.change_current_user_password(payload).then(() => {
      confirmationLogoutSavePassword()
    })
  }
})

const confirmationLogoutSavePassword = () => {
  Swal.fire({
    title: 'Importante: Guarda tu nueva contraseña',
    html: `
      <p>Tu contraseña ha sido actualizada exitosamente.</p>
      <p><strong>Se cerrará la sesión a continuación.</strong> Asegúrate de guardar tu nueva contraseña en un lugar seguro, ya que la necesitarás para iniciar sesión nuevamente:</p>
      <p style="font-size: 1.2em; font-weight: bold; color: #d9534f; cursor: pointer;" id="password-text">${password.value}</p>
      <p>Recuerda, sin esta nueva contraseña no podrás acceder al sistema.</p>
      <p id="copy-success" style="color: green; display: none;">¡Contraseña copiada al portapapeles!</p>
    `,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Entendido, continuar',
    customClass: {
      icon: 'pulse-animation'
    }
  }).then(() => {
    modalRef.value?.hide()
    logout()
  })

  Swal.getHtmlContainer()
    .querySelector('#password-text')
    .addEventListener('click', () => {
      // Crear un campo de texto temporal para copiar el contenido
      const tempInput = document.createElement('input')
      tempInput.style.position = 'absolute'
      tempInput.style.left = '-9999px'
      tempInput.value = password.value
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      // Mostrar mensaje de éxito
      const copySuccess = Swal.getHtmlContainer().querySelector('#copy-success')
      copySuccess.style.display = 'block'
    })
}

function cancelForm() {
  modalRef.value.hide()
  cleanForm()
}

function cleanForm() {
  resetForm({
    values: {
      password: '',
      password_confirmation: ''
    }
  })
}

function logout() {
  authStore.logout()
}

function generatePayload() {
  const payload = {
    password: password.value,
    password_confirmation: password_confirmation.value
  }
  return payload
}

function onShown() {
  return
}

function containUpperCase(upper) {
  const upper_letters = 'ABCDEFGHYJKLMNÑOPQRSTUVWXYZ'
  const down_letters = upper_letters.toLowerCase()
  for (let i = 0; i < password?.value?.length; i++) {
    const letters = upper ? upper_letters : down_letters
    if (letters.indexOf(password.value.charAt(i), 0) != -1) {
      return true
    }
  }
  return false
}

function containNumber() {
  return /\d/.test(password?.value)
}

function containSpecialCharacter() {
  return /[ #?!@$%^&*-]/.test(password?.value)
}

function extraValidation() {
  return (
    valuesLength.value != 0 &&
    containSpecialCharacter() &&
    containUpperCase(true) &&
    containUpperCase(false) &&
    meta.value.valid &&
    containNumber()
  )
}

defineExpose({
  showModal,
  hideModal
})
</script>
