<template>
  <div>
    <div class="row">
      <div
        class="col-12 col-lg-5 col-xl-4 d-flex justify-content-center align-items-center flex-column"
      >
        <div class="mb-5">
          <div class="text-center mb-3">
            <img
              decoding="async"
              width="245"
              height="52"
              src="@/assets/images/dinamicalogo.png"
              class="attachment-large size-large wp-image-898"
              alt=""
            />
          </div>
          <div class="text-center mb-3">
            <img
              decoding="async"
              width="161"
              height="21"
              src="@/assets/images/trupperlogo.svg"
              class="attachment-large size-large wp-image-961"
              alt=""
            />
          </div>
        </div>

        <div
          class="card card-form-login px-4 pt-4 mb-5 shadow-lg rounded-5"
          style="min-width: 350px"
        >
          <div class="card-body">
            <h2>Bienvenido a</h2>
            <h2>Dinámica en Línea</h2>
            <transition name="fade">
              <div class="alert alert-warning text-center mb-0" v-if="errorMessage">
                {{ errorMessage }}
              </div>
            </transition>
            <form @submit.prevent="login(email, password)" class="pt-4">
              <div class="mb-3">
                <label for="email" class="text-app">ID Cliente</label>
                <input
                  type="text"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="12345"
                  v-model="email"
                  v-clearInput
                />
              </div>
              <div class="mb-2">
                <label for="email" class="text-app">Contraseña</label>
                <div class="form-group mb-3 input-wrapper">
                  <input
                    v-model="password"
                    :type="inputPassword"
                    id="password"
                    class="form-control"
                    name="password"
                    required
                    @keyup.enter="login(email, password)"
                    placeholder="Contraseña"
                    v-clearInput
                  />
                  <font-awesome-icon
                    v-if="inputPassword == 'password'"
                    class="input-icon password text-500"
                    :class="{ 'micropulse-animation-eye-icon': errorMessageBadPwdMssg }"
                    :icon="['fas', 'eye-slash']"
                    @click="inputPassword = 'text'"
                  />
                  <font-awesome-icon
                    v-else
                    class="input-icon password text-500"
                    :icon="['fas', 'eye']"
                    @click="inputPassword = 'password'"
                  />
                </div>
              </div>
              <div class="d-flex align-items-center text-app mb-4">
                <div class="flex-fill">
                  <input
                    class="form-check-input me-2"
                    v-model="remember"
                    type="checkbox"
                    name="remember"
                    id="remember"
                  />
                  <small class="form-check-label" for="remember"> Recuerdame </small>
                </div>
                <div
                  class="d-flex flex-fill justify-content-center"
                  :class="{ 'pulse-animation': errorMessageBadPwdMssg }"
                >
                  <button @click="passwordRecovery" class="btn cursor-pointer" type="button">
                    <small>Recuperar contraseña</small>
                  </button>
                </div>
              </div>
              <div class="my-4">
                <button class="btn btn-primary-app py-2 w-100" type="submit">Entrar</button>
              </div>
              <div class="mt-3 d-flex align-items-center cursor-pointer">
                <p class="text-app flex-fill m-0">¿No tienes cuenta?</p>
                <p
                  class="flex-fill text-center m-0 cursor-pointer"
                  @click="router.push({ name: 'signUp' })"
                >
                  <b>Registrate aquí</b>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-xl-8 d-none d-md-none d-lg-block">
        <div class="d-flex justify-content-center align-items-center flex-column px-4 h-100">
          <div class="card-img-login h-100" :style="backgroundImageStyle"></div>
        </div>
      </div>
    </div>
    <PasswordRecovery ref="passwordRecoveryRef" />
  </div>
</template>

<script setup>
import router from '@/router'
import { ref, onMounted, computed, watch } from 'vue'
import PasswordRecovery from '@/components/account/PasswordRecovery.vue'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useShoppingStore } from '@/stores/shopping'
import { instance, factories } from '@/api'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/../firebaseConfig'

const environment = import.meta.env.VITE_ENVIRONMENT

const api = factories(instance, ['auth', 'user', 'login_image'])
const shoppingStore = useShoppingStore()
const userBalance = computed({
  get: () => shoppingStore.getUserBalance,
  set: (newValue) => {
    shoppingStore.updateUserBalance(newValue)
  }
})
const userStore = useUserStore()
const authStore = useAuthStore()
const email = ref('')
const login_images = ref([])
const passwordRecoveryRef = ref(null)
const password = ref('')
const remember = ref(false)
const inputPassword = ref('password')
const backgroundImageStyle = ref({})
const errorMessage = ref('')

const errorMessageBadPwdMssg = computed(() => {
  return errorMessage.value === 'Nombre de usuario o contraseña incorrectos.'
})

function login(email, password) {
  if (environment === 'production') {
    logEvent(analytics, 'login_user_info_try', {
      user_try: email
    })
  }
  api.auth
    .login(email, password)
    .then((res) => {
      if (remember.value) {
        localStorage.setItem('remember', remember.value)
        localStorage.setItem('email', email)
      } else {
        localStorage.removeItem('remember')
        localStorage.removeItem('email')
      }
      authStore.login(res)
      api.user.profile().then((response) => {
        // En cualquier parte de tu aplicación donde quieras enviar un evento
        if (environment === 'production') {
          logEvent(analytics, 'login_user_info', {
            client_num: response.data.client_num,
            email: response.data.email,
            price_id: response.data.price_id
          })
        }
        if (response?.data?.balance && response?.data?.user_tye === 'client_credit') {
          shoppingStore.updateNotBalanceLogic(true)
        }
        userBalance.value = response?.data?.balance || 0
        userStore.updateUserInfo(response)
      })
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        errorMessage.value = error.response.data.message
      }
    })
}

function getLoginImages() {
  api.login_image.list().then((res) => {
    login_images.value = res.data.login_images
  })
}

function passwordRecovery() {
  passwordRecoveryRef.value.showModal()
}

onMounted(() => {
  remember.value = localStorage.getItem('remember')
  if (remember.value) {
    email.value = localStorage.getItem('email')
  }
  getLoginImages()
})

// Watch for changes in login_images and update background image style
watch(login_images, () => {
  if (login_images.value.length > 0) {
    const img = new Image()
    img.src = login_images.value[0].image_url
    img.onload = () => {
      backgroundImageStyle.value = {
        background: `url(${login_images.value[0].image_url}) no-repeat center center`,
        backgroundSize: 'cover'
      }
    }
  }
})
</script>
