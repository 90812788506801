<script setup>
import router from '@/router'
import ClientForm from '@/components/forms/ClientForm.vue'
import InfoVideo from '@/components/shared/InfoVideo.vue'
import { instance, factories } from '@/api'
import { ref } from 'vue'

const api = factories(instance, ['clients'])

const infoVideoModalRegister = ref(null)

function registerClient(payload) {
  api.clients.create(payload.data).then(() => {
    router.push({ name: 'login' })
  })
}

function cancelRegister() {
  router.push({ name: 'login' })
}

function openTutorialModal() {
  infoVideoModalRegister.value.showModal()
}
</script>

<template>
  <div class="container-fluid">
    <InfoVideo
      ref="infoVideoModalRegister"
      :videoSrcProps="'https://www.youtube.com/embed/ERaXBxEUoNo?autoplay=1'"
    />
    <div class="row">
      <div
        class="col-12 col-lg-6 col-xl-6 min-vh-100 d-flex justify-content-center align-items-center flex-column"
      >
        <div class="d-flex justify-content-between align-items-center" style="min-width: 350px">
          <div class="text-center mb-3">
            <img
              decoding="async"
              width="155"
              height="33"
              src="@/assets/images/dinamicalogo.png"
              class="attachment-large size-large wp-image-898"
              alt=""
            />
          </div>
          <div class="text-center mb-3">
            <img
              decoding="async"
              width="115"
              height="15"
              src="@/assets/images/trupperlogo.svg"
              class="attachment-large size-large wp-image-961"
              alt=""
            />
          </div>
        </div>

        <div
          class="card card-form-login px-4 pt-4 mb-4 shadow-lg rounded-5"
          style="min-width: 350px"
        >
          <div class="card-body">
            <h3>Registro dinamicaenlinea.com</h3>
            <ClientForm
              @saveForm="registerClient"
              @cancelForm="cancelRegister"
              :action="'signup'"
            />
          </div>
        </div>
        <div class="mt-1 text-center text-500">
          <p>Recibirás instrucciones por correo</p>
          <p>Cualquier actualización de datos favor de contactar a su asesor de ventas</p>
          <p>
            ¿Necesitas ayuda?
            <span class="cursor-pointer" @click="openTutorialModal"
              ><button class="btn btn-outline-secondary">Ver video</button></span
            >
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 d-none d-sm-none d-md-block">
        <div class="vh-100 d-flex justify-content-center align-items-center flex-column px-4">
          <img
            src="@/assets/images/6184.png"
            class="card-img-top img-fluid"
            alt="https://placehold.co/400x400?text=No+Image"
          />
        </div>
      </div>
    </div>
  </div>
</template>
