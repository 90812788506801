<template>
  <GeneralModal
    ref="modalRef"
    @shown="onShown"
    @hidden="onHideModal"
    title="Gracias por su compra"
    :hideHeader="true"
    size="lg"
    modalContentClass="p-5 confirmationModal"
    hideHeaderClose
    hideFooter
  >
    <template #bodyContent>
      <div>
        <div class="d-flex align-items-center justify-content-center position-relative">
          <div>
            <span class="fs-2 fw-semibold text-black text-center">¡Gracias por su pedido!</span>
          </div>
          <div
            class="position-absolute"
            :class="{ lottieConfirmatons: !isBlocked, lottieConfirmatonsWarning: isBlocked }"
            v-if="isShown"
          >
            <Vue3Lottie
              ref="vue3LottieRef"
              :animationData="Warning"
              :loop="3"
              @onComplete="vue3LottieRef.destroy()"
              v-if="isBlocked"
            />
            <Vue3Lottie
              ref="vue3LottieRef"
              :loop="2"
              @onComplete="vue3LottieRef.destroy()"
              :animationData="Confetti"
              v-else
            />
          </div>
        </div>
        <hr />
        <div class="alert alert-danger fw-bold" role="alert" v-if="isBlocked">
          Por favor, revisa el estado de tu crédito. Tu pedido será procesado una vez que se aclare.
          En nuestros horarios de oficina.
        </div>
        <div class="mb-4 d-flex justify-content-center">
          <div class="col-12">
            <span class="fs-4"
              >La confirmación del pedido y las actualizaciones se enviarán a:
              <span class="fw-semibold">{{ userStore.email }}</span></span
            >
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="orderDetail">
          <div class="d-flex justify-content-between align-items-between col-12">
            <div class="col-6 fs-4">
              <div class="fw-semibold">Pedido {{ orderDetail.order_num || '-' }}</div>
              <div>{{ $formatdatetime(orderDetail.created_at) || '-' }}</div>
            </div>
            <div class="col-6 fs-4">
              <div class="fw-semibold">Estado del pedido</div>
              <div
                class="badge bg-app rounded-3 p-2 fw-normal"
                :class="$actionsstatusbadge.getClass(!isBlocked ? orderDetail.status : 'blocked')"
              >
                <span>
                  {{ $actionsstatusbadge.getLabel(!isBlocked ? orderDetail.status : 'blocked') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="mb-4 d-flex justify-content-center" v-if="!isBlocked">
          <div class="col-12">
            <h4 class="fw-semibold">El pedido se entregará en:</h4>
            <p class="fs-4 mb-1">
              {{
                `${addressesDelivery.street} ${addressesDelivery.num}, ${addressesDelivery.suburb}`
              }}
            </p>
            <p
              class="mb-1"
              v-if="addressesDelivery.num_int && Number(addressesDelivery.num_int) !== 0"
            >
              {{
                `${
                  addressesDelivery.num_int ? 'Número interior: ' + addressesDelivery.num_int : ''
                }`
              }}
            </p>
            <p class="fs-4 mb-1">
              {{ `${addressesDelivery.city_name}, ${addressesDelivery.state_name}` }}
            </p>
            <p class="fs-4 mb-1" v-if="addressesDelivery.zip && addressesDelivery.zip !== 0">
              {{ addressesDelivery.zip }}
            </p>
          </div>
        </div>
        <div class="mb-5 d-flex justify-content-center">
          <div class="col-12">
            <h4 class="fw-semibold">¿Tiene preguntas sobre su pedido?</h4>
            <div class="fs-4">Estamos aqui para ti, haznos saber cómo podemos ayudarte.</div>
            <div class="fs-4" v-if="isClientCredit">Arlet López</div>
            <div class="fs-4">Tel: 8114113238</div>
            <div class="fs-4">Email:info@dinamicaenlinea.com</div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <button @click="goHome" class="fs-5 btn btn-primary-app mt-5 py-3 px-5 rounded">
            <span class="px-5 py-3">Seguir comprando</span>
          </button>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>
<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import Confetti from '@/assets/lotties/Confetti.json'
import Warning from '@/assets/lotties/Warning.json'
import { ref, computed, toRefs } from 'vue'
import router from '@/router'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  addressesDelivery: { type: Object, default: () => {} }
})

const { addressesDelivery } = toRefs(props)
const userStore = useUserStore()
const isClientCredit = computed(() => userStore.isClientCredit)

const modalRef = ref(null)
const vue3LottieRef = ref(null)
const isShown = ref(false)
const orderDetail = ref(null)
const isBlocked = computed(() => userStore.isBlocked)

const showModal = (order = null) => {
  orderDetail.value = order
  modalRef.value.show()
}

const onHideModal = () => {
  router.push({ name: 'home' })
}

const hideModal = () => {
  isShown.value = false
  modalRef.value.hide()
}

const goHome = () => {
  modalRef.value.hide()
  router.push({ name: 'home' })
}

const onShown = () => {
  isShown.value = true
}

defineExpose({
  showModal,
  hideModal
})
</script>
