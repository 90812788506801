import { defineStore } from 'pinia'
import { reactive } from 'vue'
import router from '@/router'
import permissionsByUserType from '@/constants/config/permissions'

export const useUserStore = defineStore('user', {
  state: () => ({
    info: reactive(null),
    search: '',
    toggleSearch: false,
    blockedState: false,
    filterByClient: {
      list: [],
      id: null,
      item: null
    },
    clientSelected: null,
    clientSelectedInfo: reactive({}),
    clientCreditConfirmReadedInfo: false,
    homeRouteByUserType: {
      superadmin: 'admin.users',
      admin: 'admin.orders_list',
      manager: 'admin.orders_list',
      seller: 'admin.clients',
      prospect: 'home',
      client: 'myAccount',
      client_credit: 'home',
      customer_service: 'admin.orders_list',
      default: ''
    },
    hasSeenVideo: false
  }),
  getters: {
    getFilterByClient() {
      return this.filterByClient
    },
    getZoneUser: (state) => state.info?.zone,
    getHasSeenVideo: (state) => state.hasSeenVideo,
    isBalanceActive: (state) => state.info?.balance > 0,
    requireChangePassword: (state) => state.info?.change_password,
    getIsReadedClientCreditConfirm: (state) => state.clientCreditConfirmReadedInfo,
    getHomeRouteByUserType: (state) => state.homeRouteByUserType[state?.userType || 'default'],
    hasPermission: (state) => (routeName) => {
      const userType = state.userType
      const permissions = permissionsByUserType[userType] || permissionsByUserType.default
      return permissions.includes(routeName)
    },
    getBlockedNote: (state) => state.blockedState,
    isBlocked: (state) => !!state.blockedState,
    toggleSearchValue: (state) => state.toggleSearch,
    searchValue: (state) => state.search,
    userInfo: (state) => state.info,
    userName: (state) => state.info?.name,
    phoneNumber: (state) => state.info?.phone_number,
    email: (state) => state.info?.email,
    userType: (state) => state.info?.user_type,
    isNew: (state) => state.info?.is_new,
    firstLetterName: (state) => state.info?.name?.charAt(0),
    managerType: (state) => {
      if (state.info?.user_type === 'manager') {
        return state.info?.manager_type
      }
      return null
    },
    isAdmin: (state) => state.info?.user_type === 'admin',
    isClient: (state) => state.info?.user_type === 'client',
    isClientProspect: (state) => state.info?.user_type === 'prospect',
    isClientCredit: (state) => state.info?.user_type === 'client_credit',
    isSuperadmin: (state) => state.info?.user_type === 'superadmin',
    isSeller: (state) => state.info?.user_type === 'seller',
    isManager: (state) => state.info?.user_type === 'manager',
    isCustomerService: (state) => state.info?.user_type === 'customer_service',
    isExcludedUserByClientNum: (state) => {
      const excludedOfMyAccountViewUser = [24509]
      return excludedOfMyAccountViewUser.includes(state.info?.client_num)
    },
    routeToDasboardByUserType: (state) => {
      switch (state.info?.user_type) {
        case 'superadmin':
        case 'admin':
          return 'admin.users'
        case 'manager':
          return 'admin.clients'
        case 'seller':
          return 'admin.clients'
        case 'prospect':
          return 'signUpProspect'
        default:
          return ''
      }
    },
    actionPermissionsAdminProductListByUserType: (state) => {
      const permissions = {
        superadmin: ['all'],
        admin: ['openPreviewImage', 'addProducts', 'openDataSheet', 'openPreviewImage', 'changePage'],
        default: []
      }
      return permissions[state.info?.user_type] || permissions.default
    },
    canExportClients: (state) => {
      return ['superadmin'].includes(state.info?.user_type) || state.info?.id === 45
    },
    canFilterClients: (state) => {
      return ['superadmin', 'admin', 'manager'].includes(state.info?.user_type)
    },
    canFilterOrders: (state) => {
      return ['superadmin', 'admin', 'manager', 'customer_service'].includes(state.info?.user_type)
    },
    canEnterDashboard: (state) => {
      return (
        ['superadmin', 'admin', 'manager', 'seller', 'manager'].includes(state.info?.user_type) ||
        false
      )
    },
    canViewMyAccount: (state) => {
      return (
        ['superadmin', 'admin', 'manager', 'seller', 'manager'].includes(state.info?.user_type) ||
        false
      )
    },
    canViewMyAccountByClientNum: (state) => {
      return ['superadmin', 'admin', 'seller', 'manager'].includes(state.info?.user_type) || false
    },
    canViewProductsByClientNum: (state) => {
      return ['superadmin', 'seller'].includes(state.info?.user_type) || false
    },
    client_num: (state) => state.info?.client_num
  },
  actions: {
    setFilterByClient(key, value) {
      this.filterByClient[key] = value
    },
    updateHasSeenVideo(value) {
      this.hasSeenVideo = value
    },
    updateClientCreditConfirmReadedInfo(value) {
      this.clientCreditConfirmReadedInfo = value
    },
    updateToggleSearch() {
      this.toggleSearch = !this.toggleSearch
    },
    updateSearch(value) {
      this.search = value
      this.toggleSearch = !this.toggleSearch
    },
    updateBlockedState(value) {
      this.blockedState = value
    },
    updateUserInfo(response, requireRedirect = true) {
      this.info = response.data
      const excludedOfMyAccountViewUser = [24509]
      if (requireRedirect) {
        if (excludedOfMyAccountViewUser.includes(this.info.client_num)) {
          router.push('/')
        } else {
          router.push({ name: this.homeRouteByUserType[this.userType] })
        }
      }
    }
  },
  persist: true
})
