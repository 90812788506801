<script setup>
import ProductList from '@/components/products/ProductList.vue'
import { instance, factories } from '@/api'
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRoute } from 'vue-router'
import router from '@/router'
import SelectClientComponent from '@/components/account/SelectClientComponent.vue'

const route = useRoute()
const api = factories(instance, ['product'])

const userStore = useUserStore()

const canViewProductsByClientNum = computed(() => userStore.canViewProductsByClientNum)

const searchValue = computed({
  get: () => userStore.searchValue,
  set: (newValue) => {
    userStore.updateSearch(newValue)
  }
})

function getProductsByClient() {
  getProducts()
}

watch(searchValue, () => getProducts(1))

const products = ref([])
const page = ref(1)
const total = ref(0)
const limit = ref(20)

const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

function getProducts(pageSet = null) {
  if (searchValue.value === '') {
    router.push({ name: 'home' })
    return
  }
  page.value = pageSet ? pageSet : 1
  const payload = {
    limit: limit.value,
    page: page.value,
    search: searchValue.value
  }
  if (clientSelected.value && canViewProductsByClientNum.value) payload.client_id = clientSelected.value

  api.product.listStore(payload).then((res) => {
    products.value = res.data.products
    total.value = res.data.total
  })
}

function sizeWindow(getData = true) {
  if (window.innerWidth <= 767 && limit.value !== 14) {
    limit.value = 14
    if (getData) getProducts()
  }
  if (window.innerWidth >= 768 && window.innerWidth < 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 992 && limit.value !== 24) {
    limit.value = 24
    if (getData) getProducts()
  }
  if (window.innerWidth > 1400 && limit.value !== 28) {
    limit.value = 28
    if (getData) getProducts()
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', sizeWindow)
})

onMounted(() => {
  if (route.query.search) {
    searchValue.value = route.query.search
  }
  window.addEventListener('resize', sizeWindow)
  sizeWindow(false)
  getProducts()
})
</script>

<template>
  <div>
    <transition name="fade">
      <SelectClientComponent
        v-if="canViewProductsByClientNum"
        ref="selectClientComponent"
        @clientSelectedAction="getProductsByClient"
      />
    </transition>
    <ProductList
      paginationTop
      paginationBottom
      :products="products"
      :limit="limit"
      :total="total"
      @getProducts="getProducts"
    />
  </div>
</template>
