<template>
  <GeneralModal
    ref="modalRefClientCreditInfo"
    title="Saldo de bonos"
    :hideHeader="true"
    size="lg"
    modalContentClass="p-5"
    hideHeaderClose
    hideFooter
    noCloseOnBackdrop
    noCloseOnEsc
  >
    <template #bodyContent>
      <div>
        <WizardComponent
          :steps="steps"
          :initialStep="currentStep"
          @update:step="handleStepChange"
        />

        <div v-for="(step, index) in steps" :key="index">
          <div v-if="currentStep === index + 1">
            <div class="d-flex justify-content-around align-items-center pt-5 pb-4">
              <p class="mb-1">
                Hola, <strong>{{ onlyTwoNames }}</strong>
              </p>
              <p id="focusSaldoBono" class="mb-1" v-if="isClientCredit && getBalanceLogicState">
                <span class="me-3">Saldo de bono:</span>
                <strong class="float-end pulse-animation">
                  {{ remainingBalance < 0 ? 0 : $formatcurrency(remainingBalance) }}
                </strong>
              </p>
            </div>
            <transition name="fade">
              <div class="d-flex align-items-center py-4" v-if="showContent">
                <font-awesome-icon :icon="step.icon" class="me-4" />
                <h5 class="m-0">{{ step.content }}</h5>
              </div>
            </transition>

            <button
              class="btn btn-primary-app float-end"
              @click="nextStep"
              v-if="index + 1 < steps.length"
            >
              Siguiente
            </button>
            <button
              class="btn btn-primary-app float-end"
              @click="hideModal"
              v-if="index + 1 >= steps.length"
            >
              Confirmar
            </button>
            <button class="btn btn-primary-app float-start" @click="prevStep" v-if="index + 1 > 1">
              Atrás
            </button>
          </div>
        </div>
      </div>
    </template>
  </GeneralModal>
</template>
<script setup>
import GeneralModal from '@/components/shared/GeneralModal.vue'
import WizardComponent from '@/components/shared/WizardComponent.vue'
import { ref, computed } from 'vue'
import { useShoppingStore } from '@/stores/shopping'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const shoppingStore = useShoppingStore()

const remainingBalance = computed(() => shoppingStore.getUserBalanceMinusTotal)
const getBalanceLogicState = computed(() => shoppingStore.getBalanceLogicState)
const userName = computed(() => userStore.userName)
const onlyTwoNames = computed(() => userName.value.split(' ').slice(0, 2).join(' '))
const isClientCredit = computed(() => userStore.isClientCredit)

const modalRefClientCreditInfo = ref(null)
const showContent = ref(true)

const steps = ref([
  {
    content:
      'El importe del saldo de bono por convenio es aplicable únicamente a los productos contenidos en esta liga.',
    icon: 'fa-solid fa-link'
  },
  {
    content:
      'Tu pedido puede ser mayor al importe del bono. Se aplica el crédito y pagas la diferencia en el plazo que ya tienes autorizado en tu línea de crédito.',
    icon: 'fa-solid fa-credit-card'
  },
  {
    content:
      'Todos los productos incluyen garantía de fabricación Truper, asegurando su calidad y durabilidad.',
    icon: 'fa-solid fa-shield-alt'
  },
  { content: 'No se aceptan devoluciones sobre estos artículos.', icon: 'fa-solid fa-ban' }
])

const currentStep = ref(1)

const handleStepChange = (step) => {
  currentStep.value = step
  runAnimation()
}

const nextStep = () => {
  if (currentStep.value < steps.value.length) {
    runAnimation()
    currentStep.value++
  }
}

const prevStep = () => {
  if (currentStep.value > 1) {
    runAnimation()
    currentStep.value--
  }
}

const runAnimation = () => {
  showContent.value = false
  setTimeout(() => {
    showContent.value = true
  }, 100)
}

const showModal = () => {
  modalRefClientCreditInfo.value.show()
}

const hideModal = () => {
  userStore.updateClientCreditConfirmReadedInfo(true)
  modalRefClientCreditInfo.value.hide()
}

defineExpose({
  showModal,
  hideModal
})
</script>
