<script setup>
import { useShoppingStore } from '@/stores/shopping'
import { computed, ref, toRefs } from 'vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { useUserStore } from '@/stores/user'

const route = useRoute()
const userStore = useUserStore()

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  isSelected: {
    type: Boolean,
    default: false
  },
  selectProduct: {
    type: Boolean,
    default: false
  },
  hidePrice: Boolean,
  hideAddBtn: Boolean,
  showBorder: Boolean,
  hideDiscount: Boolean,
  hideKey: {
    type: Boolean,
    default: false
  },
  hasWatermark: {
    type: Boolean,
    default: false
  }
})

const { product, selectProduct } = toRefs(props)

const emit = defineEmits(['selectProduct'])

const clickOnAddOrFavorite = ref(false)
const shoppingStore = useShoppingStore()

const isThisProductInCart = computed(
  () => shoppingStore.isThisProductInCart(product?.value?.id) || false
)

// const amount = computed(() => shoppingStore.checkAmount(product.value.id))

const amount = computed({
  get: () => shoppingStore.getAmountByID(product.value.id),
  set: (newValue) => {
    shoppingStore.changeAmountWithoutNotification(product.value.id, newValue)
  }
})

const subtractOne = (id) => {
  shoppingStore.subtractOne(id)
}

const addOne = (id) => {
  shoppingStore.addOne(id)
}

const changeAmount = (id, amount) => {
  shoppingStore.changeAmount(id, amount)
}

const isRoutePublic = computed(() =>
  ['product_detail_public', 'product_detail_only_list_pack_public', 'category_public'].includes(
    route.name
  )
)
const isExcludedUserByClientNum = computed(() => userStore.isExcludedUserByClientNum)
const isClientCredit = computed(() => userStore.isClientCredit)

function addProduct() {
  shoppingStore.addItem(product.value, 1)
}

function handleClick(event) {
  if (selectProduct.value) {
    event.preventDefault()
    clickOnAddOrFavorite.value = true
    emit('selectProduct', product.value.id)
    return
  }
  if (
    event.target.tagName === 'BUTTON' ||
    event.target.tagName === 'svg' ||
    event.target.tagName === 'INPUT'
  ) {
    clickOnAddOrFavorite.value = true
    event.preventDefault()
  }
}

onBeforeRouteLeave((to, from, next) => {
  if (clickOnAddOrFavorite.value) {
    clickOnAddOrFavorite.value = false
    next(false)
  } else {
    next()
  }
})
</script>

<template>
  <router-link
    v-if="product?.id"
    class="card card_product cursor-pointer text-decoration-none h-100 p-2"
    :class="{
      'border border-secondary-subtle': showBorder,
      'border-0': !showBorder && !isThisProductInCart,
      'selected-card': isSelected,
      'border-primary-app': isThisProductInCart
    }"
    :to="{
      name: isRoutePublic ? 'product_detail_public' : 'productDetail',
      params: { id: product?.id }
    }"
    @click="handleClick($event)"
  >
    <div class="d-flex flex-column h-100">
      <!-- Image Section -->
      <div class="position-relative w-90 mx-auto">
        <div class="image-container" v-if="hasWatermark">
          <img :src="product.image" class="img-fluid" :alt="product.title" />
          <div class="watermark" v-if="product.watermark">{{ product.watermark }}</div>
        </div>
        <img :src="product.image" class="img-fluid" :alt="product.title" v-else />

        <div v-if="product?.categories?.includes(5)" class="position-absolute bottom-0 start-0 z-1">
          <img
            src="@/assets/images/av_icon.svg"
            alt="Brand Image"
            class="img-fluid av-image-width-card position-relative"
          />
        </div>
        <div v-if="product.sold_out" class="position-absolute float_element-right">
          <div class="badge rounded-pill text-bg-danger">
            <h5 class="m-0 m-1 mx-2">AGOTADO</h5>
          </div>
        </div>
        <div
          v-if="product.discount > 0 && !product.sold_out && !hideDiscount"
          :class="{ 'animate-discount': product.discount >= 30 }"
          class="position-absolute float_element-right"
        >
          <font-awesome-layers full-width class="fa-4x">
            <font-awesome-icon :icon="['fas', 'tags']" class="ms-2 text-danger" />
            <font-awesome-layers-text
              class="text-white ms-2"
              transform="shrink-12 up-1 down-1"
              :value="`${product.discount}%`"
            />
          </font-awesome-layers>
        </div>
      </div>

      <!-- Product Info Section -->
      <div class="flex-fill p-3">
        <p class="card-text fw-medium text-black mb-2" v-text="product.title"></p>
        <div class="row g-0">
          <div class="col-6">
            <h6 class="card-subtitle fw-normal text-app mb-1" v-if="!isClientCredit">
              {{ `Código: ${product.code}` }}
            </h6>
          </div>
          <div class="col-6" v-if="!hideKey">
            <h6 class="card-subtitle fw-normal text-app mb-1" v-if="!isClientCredit">
              {{ `Clave: ${product.key}` }}
            </h6>
          </div>
        </div>

        <h6
          class="card-subtitle fw-normal text-app mt-2"
          v-if="!isClientCredit && product.multiple"
        >
          {{ `Múltiplo: ${product.multiple}` }}
        </h6>
        <h6 class="card-subtitle fw-normal text-app mb-3" v-if="product.stock">
          {{ `Existencia: ${product.stock}` }}
        </h6>
      </div>

      <!-- Footer Section -->
      <div class="px-3 pb-3 mt-auto">
        <div v-if="!hidePrice" class="mb-2">
          <div class="d-flex" v-if="product.discount > 0">
            <h5 class="fw-semibold m-0">{{ $formatcurrency(product.price_discount) }}</h5>
            <h6 class="fw-normal text-decoration-line-through text-muted m-0 red-strike ms-2">
              {{ $formatcurrency(product.price) }}
            </h6>
          </div>
          <div v-else>
            <h5 class="fw-semibold m-0">{{ $formatcurrency(product.price) }}</h5>
          </div>
        </div>

        <div v-if="!hideAddBtn && !isThisProductInCart">
          <button
            v-if="!isRoutePublic && !isExcludedUserByClientNum"
            :disabled="product.sold_out"
            @click="addProduct"
            class="btn btn-primary-app-2 w-100"
          >
            {{ !product.sold_out ? 'Agregar' : 'Agotado' }}
          </button>
        </div>
        <div v-if="isThisProductInCart">
          <div class="input-group mb-3">
            <button
              class="btn btn-outline-secondary border z-1"
              type="button"
              @click="subtractOne(product.id)"
            >
              <font-awesome-icon icon="fa-solid fa-minus" />
            </button>
            <input
              type="number"
              class="form-control text-center"
              v-min-value
              v-numeric
              @keyup.enter="changeAmount(product.id, amount)"
              v-model="amount"
            />
            <button
              class="btn btn-outline-secondary border z-1"
              type="button"
              @click="addOne(product.id)"
            >
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss">
@keyframes pulse {
  0%,
  100% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.5);
  }
}

.animate-discount {
  animation: pulse 1.5s infinite;
}

.selected-card {
  background-color: #e6f9e6;
  border: 2px solid #36e13b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #d4f7d4;
    border-color: #2fcc30;
  }
}

.image-container {
  position: relative;
  display: inline-block;
}

.watermark {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  color: #000000b3;
  animation: pulsewatermark 1.5s infinite;
}

@keyframes pulsewatermark {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
