<template>
  <div class="mb-5">
    <transition name="fade">
      <SelectClientComponent
        ref="selectClientComponent"
        @clientSelectedAction="changeClientReloadData"
        v-if="canViewMyAccountByClientNum"
      />
    </transition>

    <TransitionGroup tag="myaccount" name="fade" key="myaccountkey">
      <template v-if="!canViewMyAccountByClientNum || clientSelected">
        <OptionsMenuComponent
          :configOptions="dynamicOptionsMenu"
          @goTo="changeActiveView"
          class="mb-3"
        />
        <div ref="pdfContent" class="pdf-content">
          <component
            ref="componentActiveRef"
            :is="componentActive"
            :class="{ 'mb-5': activeView === 'info' }"
            @changePage="getOrders"
            @action="actionMenu"
            @bgBackorder="getOrders"
            :backOrderSelected="backOrderSelected"
            :activeView="activeView"
            :info="infoAccount"
            :records="orders"
            :totalRecords="totalOrders"
            :limit="20"
            :hideBackBtn="true"
          />
          <StateAccount
            v-if="activeView === 'info' && !isClientCredit"
            ref="stateAccountListComponent"
            @changePage="getStateAccount"
            :info="infoStateAccount"
            :records="stateAccount"
            :totalRecords="totalStateAccount"
            :limit="20"
          />
          <CreditNotes
            v-if="activeView === 'info' && !isClientCredit && creditNotes.length > 0"
            ref="creditNoteListComponent"
            @changePage="getCreditNotes"
            @actionPoint="actionMenuCreditsNotes"
            :info="infoStateAccount"
            :records="creditNotes"
            :totalRecords="totalCreditNotes"
            :limit="20"
          />
          <ChargeNotes
            v-if="activeView === 'info' && !isClientCredit && chargeNotes.length > 0"
            ref="chargeNoteListComponent"
            @changePage="getStateAccount"
            :info="infoStateAccount"
            :records="chargeNotes"
          />
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>

<script setup>
import router from '@/router'
import { useRoute } from 'vue-router'
import { instance, factories } from '@/api'
import { onMounted, ref, getCurrentInstance, watch, computed } from 'vue'
import OptionsMenuComponent from '@/components/shared/OptionsMenuComponent.vue'
import InfoAccount from '@/components/account/InfoAccount.vue'
import OrdersAccount from '@/components/account/OrdersAccount.vue'
import StateAccount from '@/components/account/StateAccount.vue'
import SelectClientComponent from '@/components/account/SelectClientComponent.vue'
import NotFoundView from './NotFoundView.vue'
import { useUserStore } from '@/stores/user'
import html2pdf from 'html2pdf.js'
import CreditNotes from '@/components/account/CreditNotes.vue'
import ChargeNotes from '@/components/account/ChargeNotes.vue'

const userStore = useUserStore()
const globalProperties = getCurrentInstance().appContext.config.globalProperties
const formatCurrency = globalProperties.$formatcurrency
const formatDateTime = globalProperties.$formatdatetime
const route = useRoute()
const api = factories(instance, ['order', 'clients'])
const activeView = ref('info')
const optionsMenuConfig = ref([
  {
    name: 'Información',
    class_active: true,
    goTo: 'info'
  },
  {
    name: 'Estatus de pedidos',
    class_active: false,
    goTo: 'orders'
  },
  {
    name: 'Pedidos guardados',
    class_active: false,
    goTo: 'quotations'
  },
  // {
  //   name: 'Backorder',
  //   hasIcon: false,
  //   class_active: false,
  //   goTo: 'backorder'
  // },
  {
    name: 'Imprimir en PDF',
    hasIcon: true,
    icon: ['fas', 'file-pdf'],
    class_active: false,
    goTo: 'pdf'
  }
])

const actionMenuCreditsNotes = (option) => {
  if (option.action === 'downloadInvoice') {
    downloadFromUrl(option.info.file_pdf, 'NotaCrédito')
  } else if (option.action === 'downloadPDFXML' && option?.info?.file_zip) {
    downloadFromUrl(option.info.file_zip, 'notacredito')
  }
}

const dynamicOptionsMenu = computed(() => {
  const clientCreditOptions = ['orders']
  const optionsList = JSON.parse(JSON.stringify(optionsMenuConfig.value))
  if (activeView.value !== 'info') {
    if (isClientCredit.value) {
      return optionsList.filter((option) => clientCreditOptions.includes(option.goTo))
    }
    return optionsList.filter((option) => option.goTo !== 'pdf')
  } else if (isClientCredit.value) {
    return optionsList.filter((option) => clientCreditOptions.includes(option.goTo))
  }
  return optionsList
})
const infoStateAccount = ref({})
const infoAccount = ref({})
const stateAccount = ref([])
const creditNotes = ref([])
const orders = ref([])
const chargeNotes = ref([])
const backOrderSelected = ref([])
const totalOrders = ref(0)
const totalStateAccount = ref(0)
const totalCreditNotes = ref(0)
const componentActiveRef = ref()

const clientSelected = computed({
  get: () => userStore.getFilterByClient['id'],
  set: (newValue) => {
    userStore.setFilterByClient('id', newValue)
  }
})

const isAdminRouteView = computed(() => route.name.includes('admin'))

const componentActive = computed(() => {
  if (activeView.value === 'policy') {
    return NotFoundView
  }
  return activeView.value === 'info' ? InfoAccount : OrdersAccount
})

const actionMenu = (option) => {
  if (option.action === 'orderDetail') {
    router.push({
      name: !isAdminRouteView.value ? 'orderDetail' : 'admin.order_detail',
      params: { id: option?.info?.id }
    })
  } else if (option.action === 'backorder') {
    router.push({ name: 'backorder', params: { id: option?.info?.id } })
  } else if (option.action === 'downloadXML' && option?.info?.invoice_pdf) {
    downloadFromUrl(option.info.invoice_xml, 'FacturaPrueba')
  } else if (option.action === 'downloadPDFXML' && option?.info?.invoice_pdf) {
    downloadFromUrl(option.info.invoice_zip, 'FacturaPrueba')
  } else if (option.action === 'downloadInvoice' && option?.info?.invoice_zip) {
    downloadFromUrl(option.info.invoice_pdf, 'FacturaPrueba')
  }
}

const downloadFromUrl = (url, filename) => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.download = filename // opcional, establece el nombre del archivo descargado
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getCreditNotes = ({ page = 1 }) => {
  const limit = 20
  const payload = {
    page,
    limit
  }

  api.clients.creditNotes(clientSelected.value, payload).then((res) => {
    res.data.credit_notes.map((item, index) => {
      item.index = index + 1
      item.date = formatDateTime(item.date, 'DD-MM-YYYY')
      item.total = `${formatCurrency(item.total)}`
    })

    creditNotes.value = res.data.credit_notes
    totalCreditNotes.value = res.data.credit_notes_total
  })
}

const getStateAccount = ({ page = 1 }) => {
  const limit = 20
  const payload = {
    page,
    limit
  }

  api.clients.stateAccount(clientSelected.value, payload).then((res) => {
    const hasSomeDuplicated = res.data.account_status.some((item) => item.duplicated)
    if (hasSomeDuplicated) {
      res.data.account_status = res.data.account_status.filter((item) => !item.duplicated)
    }
    res.data.account_status.map((item, index) => {
      item.index = index + 1
      item.date_billing = formatDateTime(item.date_billing, 'DD-MM-YYYY')
      item.date_expiration = formatDateTime(item.date_expiration, 'DD-MM-YYYY')
      item.unexpired = `${formatCurrency(item.unexpired)}`
      item.balance = `${formatCurrency(item.balance)}`
      item.total = `${formatCurrency(item.total)}`
      item.expired_3 = `${formatCurrency(item.expired_3)}`
      item.expired_10 = `${formatCurrency(item.expired_10)}`
      item.expired_20 = `${formatCurrency(item.expired_20)}`
      item.expired_more = `${formatCurrency(item.expired_more)}`
    })

    stateAccount.value = res.data.account_status
    chargeNotes.value = res.data?.charge_notes || []
    infoStateAccount.value = res.data
    if (stateAccount.value.length > 0 && !hasSomeDuplicated) {
      stateAccount.value[stateAccount.value.length] = {
        index: stateAccount.value.length + 1,
        date_billing: 'Total:',
        date_expiration: '',
        total: `${formatCurrency(infoStateAccount.value.total_total)}`,
        days: 'Totales:',
        balance: `${formatCurrency(infoStateAccount.value.total_balance)}`,
        unexpired: `${formatCurrency(infoStateAccount.value.total_unexpired)}`,
        expired_3: `${formatCurrency(infoStateAccount.value.total_expired_3)}`,
        expired_10: `${formatCurrency(infoStateAccount.value.total_expired_10)}`,
        expired_20: `${formatCurrency(infoStateAccount.value.total_expired_20)}`,
        expired_more: `${formatCurrency(infoStateAccount.value.total_expired_more)}`
      }
    }

    totalStateAccount.value = res.data.account_status_total
  })
}

const getOrders = ({ page = 1, isQuotation = false, isBackorder = false, bgBackorder = false }) => {
  const limit = 20
  const payload = {
    page,
    limit
  }
  if (isQuotation) {
    payload.status = 'quotation'
  }
  if (isBackorder) {
    payload.backorder = 1
    delete payload.page
    delete payload.limit
  }
  let endpoint = 'orders'
  if (isClientCredit.value) {
    payload.client_credit = 1
    endpoint = 'credit_orders'
  }
  api.clients.orders(clientSelected.value, payload, endpoint).then((res) => {
    backOrderSelected.value = []
    if (activeView.value === 'backorder') {
      componentActiveRef.value.resetSelectedBackorders()
    }
    if (bgBackorder) {
      res.data.orders.forEach((order) => {
        order.trClass = order.backorder ? 'bg-yellow-100' : ''
        backOrderSelected.value.push(false)
      })
    }
    totalOrders.value = res.data.total
    orders.value = res.data.orders
  })
}

const getClientInfo = () => {
  api.clients.info(clientSelected.value).then((res) => {
    infoAccount.value = res.data
    userStore.updateBlockedState(res.data.blocked_note)
    if (!canViewMyAccountByClientNum.value) {
      userStore.setFilterByClient('item', res.data)
    }
  })
}

const changeActiveView = (option) => {
  if (option.goTo === 'pdf') return downloadPDF()
  const goTo = option.goTo
  activeView.value = goTo
  if (goTo === 'orders') {
    getOrders({})
  } else if (goTo === 'quotations') {
    getOrders({ isQuotation: true })
  } else if (goTo === 'backorder') {
    getOrders({ isBackorder: true })
  } else {
    getClientInfo()
    getStateAccount({})
    getCreditNotes({})
  }
  optionsMenuConfig.value.forEach((option) => {
    option.class_active = option.goTo === goTo
  })
}

const downloadPDF = () => {
  const element = document.querySelector('.pdf-content')
  if (!element) {
    console.error('Element not found')
    return
  }

  const opt = {
    margin: 0.3,
    filename: `micuenta-${infoAccount.value.client_num || ''}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  }

  html2pdf()
    .from(element)
    .set(opt)
    .save()
    .catch((err) => {
      console.error(err)
    })
}

const canViewMyAccountByClientNum = computed(() => userStore.canViewMyAccountByClientNum)
const isClientCredit = computed(() => userStore.isClientCredit)

const changeClientReloadData = () => {
  if (activeView.value === 'orders') {
    getOrders({})
  } else if (activeView.value === 'quotations') {
    getOrders({ isQuotation: true })
  } else if (activeView.value === 'backorder') {
    getOrders({ isBackorder: true })
  } else {
    getClientInfo()
    getStateAccount({})
    getCreditNotes({})
  }
}

const onFirstLoad = () => {
  getClientInfo()
  if (route.query?.activeView === 'orders') {
    changeActiveView({ goTo: 'orders' })
  } else if (route.query?.activeView === 'quotations') {
    changeActiveView({ goTo: 'quotations' })
  } else if (route.query?.activeView === 'backorder') {
    getOrders({ isBackorder: true })
  } else {
    getStateAccount({})
    getCreditNotes({})
  }
}

watch(route, () => {
  if (route.query?.activeView) {
    changeActiveView({ goTo: route.query?.activeView })
  } else {
    changeActiveView({ goTo: 'info' })
  }
})

onMounted(() => {
  if (canViewMyAccountByClientNum.value && !clientSelected.value) return
  onFirstLoad()
})
</script>
